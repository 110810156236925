import OtpVerify from "../components/OtpVerify";
import "./pledge.css";
import { useEffect, useState } from "react";
import EnterNamePhonenumber from "../components/EnterNamePhonenumber";
import firebase from "../common/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Share from "../components/Share";
import {
  fetchPledgeUserCount,


  saveUserPledgeData,
} from "../common/firebaseQuery.js/query";
import CommonHelmet from "../common/Helmet/CommonHelmet";
import { Col, Container, Row, Spinner } from "reactstrap";
import { toPng } from "html-to-image";
import download from "downloadjs";
import Home from "../components/Home";
import React from "react";



const Pledge = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showName, setShowName] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pledgeUserCount, setPledgeUserCount] = useState(
    parseInt(process.env.REACT_APP_PLEDGE_USER_OFFSET)
  );


  const [name, setName] = useState("");
  const [shareLink, setShareLink] = useState("");


  const [loader, setLoader] = useState(false);
  let userPhoneNumber = "";
  let countOffset = 14;
  // window.location.href ="http://localhost:3001/certificate/GfNSpnCPV8upDLH9vRPf";

  useEffect(() => {
    fetchPledgeUserCount()
      .then((count) => {
        setPledgeUserCount(count);
      })
      .catch((error) => {
        console.error(error);
        setPledgeUserCount(parseInt(process.env.REACT_APP_PLEDGE_USER_OFFSET));
      });

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "captcha-otp",
      {
        size: "invisible",
      }
    );
    return () => {
      window.recaptchaVerifier.clear();
    };
  }, []);

  const generateOtp = async () => {
    setLoader(true);
    const phoneNumber = `+91${userPhoneNumber}`;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoader(false);
        window.gtag("event", "clicked on resend Otp", {
          event_category: "Events",
          event_label: "resend Otp",
        });
      })
      .catch((error) => {
        setLoader(false);
        toast("Oops! Please try again");
        console.error("error " + error);
      });
  };

  const getOtp = () => {
    setPledgeUserCount(pledgeUserCount + 3 * countOffset);
    setShowName(false);
    setShowOtp(true);
    generateOtp();
  };

  const sendOtp = (number) => {
    if (number && number.length === 10) {
      window.fbq('track', 'Lead');
      userPhoneNumber = number;
      setPhoneNumber(number);
      getOtp();
    } else {
      toast("Please enter valid phone number");
    }
  };

  const saveUserData = (certificateUrl) => {
    saveUserPledgeData(name, phoneNumber, certificateUrl)
      .then(() => {
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  const verifyOtp = async (otp) => {
    setLoader(true);
    const confirmationResult = window.confirmationResult;
    try {



      await confirmationResult?.confirm(otp);
      setLoader(false);
      setPledgeUserCount(pledgeUserCount + 4 * countOffset);
      setShowOtp(false);
      setShowShare(true);
      setLoader(true);
      window.fbq('track', 'CompleteRegistration');
      window.gtag("event", "Clicked on verify Otp", {
        event_category: "Events",
        event_label: "verified otp and proceeded",
      });

    } catch (error) {
      setLoader(false);
      window.gtag("event", "entered wrong otp", {
        event_category: "Events",
        event_label: "wrong otp",
      });
      toast("Oops! Please try again");
      console.error(error);
    }
  };

  const editNumber = () => {
    setShowOtp(false);
    setShowName(true);
  };

  const signForPledge = () => {
    setShowName(true);
    setPledgeUserCount(pledgeUserCount + countOffset);
    window.fbq('track', 'ViewContent');
    window.gtag("event", "clicked on sign pledge", {
      event_category: "Events",
      event_label: "signed pledge",
    });
  };

  const downloadCertificate = () => {
    window.fbq('trackCustom', 'DownloadCertificate');
    toPng(document.getElementById("certificate-id")).then(function (dataUrl) {
      download(dataUrl, "Pledge-Certificate.png");
    });

    window.gtag("event", "clicked on download Certificate", {
      event_category: "Events",
      event_label: "Download Certificate",

    });
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        {/* <Col md={12}>
          <div className='header'>
            <div className='header-content'>
              <div className='col-12 pledge-count'>{pledgeUserCount}</div>
              <div className='col-12 pledge-update-text'>
                Bhartiya have already Signed the Pledge
              </div>
            </div>
          </div>
        </Col> */}
        <Col md={12} className="body text-center">
          {!showShare && !showName && !showOtp && (
            <Home
              pledgeUserCount={pledgeUserCount}
              startPledge={() => signForPledge()}
            />
          )}
          {showName && (
            <EnterNamePhonenumber
              name={name}
              updateName={(name) => setName(name)}
              getOtp={(number) => sendOtp(number)}
              phoneNumber={phoneNumber}
            />
          )}
          {showOtp && (
            <OtpVerify
              number={phoneNumber}
              resendOtp={() => generateOtp()}
              editNumber={() => editNumber()}
              verifyOtp={(otp) => verifyOtp(otp)}
            />
          )}
          {showShare && (
            <Share
              name={name}
              phoneNumber={phoneNumber}
              certificateImageUrl={(certificateUrl) =>
                saveUserData(certificateUrl)
              }
              downloadCertificate={downloadCertificate}
            />
          )}
        </Col>
        {!showShare && <div id="captcha-otp" />}
      </Row>

      {loader && (
        <Row className="justify-content-center">
          <Col md={12}>
            <div className="spinner-wrapper">
              <Spinner
                color="primary"
                className="center-align"
                size={45}
                children={null}
              ></Spinner>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Pledge;
