import "./App.css";
import React from "react";
import Pledge from "./pages/Pledge";
import Certificate from "./pages/Certificate";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useMediaQuery } from "react-responsive";

function App() {
  console.log("working");
  window.isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  window.isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  window.isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  window.isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  window.isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  window.isMobile = useMediaQuery({ maxWidth: 767 });

  function noop() {}

  // hiding all console logs from production
  if (process.env.NODE_ENV !== "development") {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Pledge} />
          {/* <Route exact path="/certificate/:id" component={Certificate} /> */}
        </Switch>
      </Router>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} />
    </>
  );
}

export default App;
