const cloudinaryUploadPreset = process.env.REACT_APP_EVENT_CLOUDINARY_UPLOAD_PRESET;
const cloudinaryBaseUrl = process.env.REACT_APP_EVENT_CLOUDINARY_BASE_URL;
const cloudinaryContentUrl = process.env.REACT_APP_EVENT_CLOUDINARY_CONTENT_URL;
const cloudinaryContentVersion = process.env.REACT_APP_EVENT_CLOUDINARY_CONTENT_VERSION;

const uploadCertificate = (imageData) => {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('file', imageData);
        data.append('upload_preset', cloudinaryUploadPreset);
        fetch(`${cloudinaryBaseUrl}/image/upload`, {
            method: 'POST',
            body: data
        })
        .then(async res => {
            let response = await res.json();
            console.log(response);
            if (response.error) {
                console.log(response.error);
                reject(response.error);
            } else {
                const link =
                  `${cloudinaryContentUrl}/image/upload/q_auto/${cloudinaryContentVersion}/` +
                  response.public_id;
                console.log(link);
                resolve(link);
            }
        })
        .catch(err => {
            console.log(err);
            reject(err);
        });
    })
}

export default uploadCertificate;