import React, { useEffect, useState } from "react";

import { Col, Container, FormGroup, Input, Row } from "reactstrap";

const EnterName = ({ name, updateName, submitName, getOtp, phoneNumber }) => {
  const [phone, setPhone] = useState(phoneNumber);
  const valueChange = (e) => {
    setPhone(e.target?.value);
  };
  const setUserName = (e) => {
    updateName(e.target?.value);
  };

  const signEventTracker = () => {
    window.gtag("event", "clicked on sign the pledge", {
      event_category: "Events",
      event_label: "signed the pledge",
    });
  };

  //to track window closing just after entering name and no phone number

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    if (name != "" && phoneNumber == "") {
      window.gtag("event", "closed the tab after entering just name", {
        event_category: "Events",
        event_label: "left without entering number",
      });
    }
  });

  return (
    <div className="container p-0">
      <Row>
        <div className="name-screen-title">
          Enter the following details to sign the pledge
        </div>
        <Col md={12}>
          <FormGroup>
            <label className="phone-number-label">Name</label>
            <Input
              value={name}
              className="name mt-1 mb-1"
              onChange={setUserName}
            />
          </FormGroup>
          <FormGroup>
            <label className="phone-number-label">Mobile Number</label>
            <Input
              placeholder={"Enter 10 digit number"}
              value={phone}
              className={"phone-number mt-1 mb-1"}
              maxLength={10}
              onChange={valueChange}
            />
          </FormGroup>
          <div className="next-button">
            <button
              className="selected-button"
              onClick={() => {
                signEventTracker();
                getOtp(phone);
              }}
            >
              <div className="selected-button-text"> Sign the Pledge</div>
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EnterName;
