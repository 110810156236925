import { Col, Container, Row } from "reactstrap"
import image from '../assets/pledgehome.svg';
import React from "react";


const Home = ({pledgeUserCount, startPledge}) => {


    //to track source/medium of person entering web page
    
    React.useEffect(() => {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
  			var source = "mobile"
		} else {
			source = "Desktop"
		}
        window.gtag("event", "source/medium", {
            event_category: "Events",
            event_label: `${source}`
        });
      }, [])

     




    return(
        <div className="container p-0">
            <Row>
                <Col md={12}>
                <div className="pledge-count">{pledgeUserCount}</div>
                <div className="pledge-update-text">Indians have already Signed the Pledge</div>
                <img src={image} alt="pledge for Made In India" className="home-img" onClick={() => startPledge()} />
                </Col>
            </Row>
        </div>
    )
}

export default Home;