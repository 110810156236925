import axios from 'axios'
import firebase from '../firebase'

const fetchPledgeUserCount = () => {
    const pledgeUserCountOffset = process.env.REACT_APP_PLEDGE_USER_OFFSET
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/pledge`)
        .then(res => {
            const data = res.data;
            const count = data.count;
            const totalUser = parseInt(pledgeUserCountOffset) + count;
            resolve(totalUser);
        })
        .catch(error => {
            reject(error);
        })
    })
}

const saveUserPledgeData = (name, phoneNumber, certificateUrl) => {
    const data = {
        name,
        phoneNumber,
        certificateUrl
    }
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/pledge`, data)
        .then(() => resolve())
        .catch(error => {
            reject(error);
        })
    })
}

const fetchUserName = (id) => {
    return new Promise((resolve, reject) => {
        firebase.firestore()
        .collection('pledgeUsers')
        .doc(id)
        .get()
        .then(doc => {
            if(doc.exists){
                const data = doc.data();
                resolve(data)
            }else{
                resolve('User')
            }
        })
        .catch(error => {
            reject(error);
        })
    })
}

export {
    fetchPledgeUserCount,
    saveUserPledgeData,
    fetchUserName
}