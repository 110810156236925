import { useState } from "react";
import { Col, Container, Input, Row } from "reactstrap";
import backarrow from "../assets/backarrow.svg";
import resend from "../assets/resend.svg";
import { useBeforeunload } from 'react-beforeunload';

const OtpVerify = ({ number, editNumber, verifyOtp, resendOtp }) => {
  const [otp, setOtp] = useState("");
  const updateOtp = (e) => {
    setOtp(e.target?.value);
  };


  useBeforeunload((event) => {
    event.preventDefault();
    if (otp == "") {
      window.gtag("event", "closed the tab before entering otp", {
        event_category: "Events",
        event_label: "did not add otp",
      });
  }});



  return (
    <div className="container p-0">
      <Row>
        <Col md={12}>
          <a className="edit-number" onClick={() => editNumber()}>
            <img src={backarrow} className="back-arrow" />
            <span> Edit number</span>
          </a>

          <label className="otp-label">Enter OTP</label>
          <Input
            className="name"
            maxLength={6}
            value={otp}
            onChange={(e) => updateOtp(e)}
          />
          <a className="resend-otp" onClick={() => resendOtp()}>
            <img src={resend} className="resend-img" />
            <span> Resend OTP</span>
          </a>
          <div className="next-button col-12">
            <button className="selected-button" onClick={() => verifyOtp(otp)}>
              <div className="selected-button-text"> Verify OTP & Proceed</div>
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default OtpVerify;
