import { toPng } from "html-to-image";
import { useEffect } from "react";
import uploadCertificate from "../common/uploadCertificate";
import thankyou from "../assets/thankyou.svg";
import downloadarrow from "../assets/downloadarrow.svg";
import whatsapp from "../assets/whatsapp.svg";

const Share = ({ name, certificateImageUrl, downloadCertificate, phoneNumber }) => {
    const shareMessage = `I pledged to support #MakeInIndia. You can also support #MakeInIndia by signing this pledge https://vayam.app/make-in-india-pledge/?utm_source=referral-${name}-${phoneNumber}&utm_medium=whatsapp&utm_campaign=whatsapp. Jai Hind Jai Bharat.`
    const whatsappShareMessage = encodeURIComponent(shareMessage); // encode message so that can share it on whatsapp
    const uploadImage = (imageData) => {
        uploadCertificate(imageData)
            .then((certificateUrl) => {
                certificateImageUrl(certificateUrl);
            })
            .catch((error) => {
                console.error(error);
                certificateImageUrl(""); // if not uploaded certificate save the empty string and allow user to move next step
            });
    };

    const inviteEventTracker = () => {
        window.gtag("event", "clicked on invite Others", {
            event_category: "Events",
            event_label: `${name} invited others via whatsapp`,
        });
    };

    useEffect(() => {
        toPng(document.getElementById("certificate-id"), { cacheBust: true })
            .then((dataUrl) => {
                uploadImage(dataUrl);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);
    return (
        <div className="container p-0">
            <img src={thankyou} alt="thank you" className="thankyou-img" />
            <div className="thankyou-message">
                for supporting “Make In India”. Download your certificate and share with
                your friends and family.
            </div>
            <div className="certificate-share-parent">
                <div className="certificate" id="certificate-id">
                    <p className="certificate-name">{name}</p>
                </div>
            </div>
            <div
                className="download-certificate-text"
                onClick={() => downloadCertificate()}
            >
                <img src={downloadarrow} className="downlaod-arrow" />
                <span> Download Certificate</span>
            </div>
            <div className="whatsapp-share col-12">
                <a
                    href={`https://wa.me?text=${whatsappShareMessage}`}
                    target="_blank"
                    className="w-fill"
                    rel="noreferrer"
                >
                    <button className="whatsapp-share-button">
                        <div onClick={inviteEventTracker}>
                            <img src={whatsapp} className="whatsapp-share-image" />
                            Invite Others to Take The Pledge
                        </div>
                    </button>
                </a>
            </div>
        </div>
    );
};

export default Share;
